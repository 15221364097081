import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../../../../states/hooks";
import { selectMiners } from "../../../../../states/minersState";
import { asRSAddress } from "../../../../../app/utils/functions/accountAddress";

import Chart from "react-apexcharts";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

export const MinePoolShareChart = () => {
  const { t } = useTranslation();
  const { t: customT } = useTranslation("custom");
  const miningData = useAppSelector(selectMiners);
  const { isLoading: isLoadingMiningData, miners } = miningData;

  const [minersShare, updateMinersShare] = useState({
    accountLabels: [],
    share: [],
  });

  const [isLoading, setIsLoading] = useState(true);

  const populateDataIntoChart = () => {
    let accountLabels: any = [];
    let share: any = [];
    let totalSum = 0;

    // Check if pool has no miners
    if (miners && miners.length === 0) return setIsLoading(false);

    // Count of miners to show
    const minersCount = 10;

    // Loop through miners
    miners.map((miner, index) => {
      // Ignore other miners
      if (index > minersCount) {
        return false;
      }

      // Add stats of other miners
      if (index === minersCount) {
        accountLabels.push(customT("otherMiners"));

        return share.push(100 - totalSum);
      }

      // Add miners address or username
      accountLabels.push(miner.name || asRSAddress(miner.accountId));

      const minerShare = parseFloat((miner.poolShare * 100).toFixed(3));

      share.push(minerShare);

      totalSum += minerShare;
    });

    updateMinersShare({
      accountLabels,
      share,
    });

    return setIsLoading(false);
  };

  useEffect(() => {
    if (!isLoadingMiningData) populateDataIntoChart();
  }, [isLoadingMiningData, miners]);

  // Pie chart format

  const chartSeries = minersShare.share;

  const chartColors = [
    "#3BA272",
    "#5470C6",
    "#91CC75",
    "#FAC858",
    "#EE6666",
    "#73C0DE",
    "#B2F068",
    "#FFA500",
    "#ADA7FC",
    "#FC8452",
    "#474F5B",
  ];

  if (isLoading)
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Paper
          elevation={2}
          sx={{ color: "white", p: 4, borderRadius: 2, mt: 3 }}
        >
          <CircularProgress color="primary" size={75} />
        </Paper>
      </Grid>
    );

  if (!miners.length)
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Paper elevation={2} sx={{ p: 4, borderRadius: 2, mt: 3 }}>
          <Typography align="center" variant="h6">
            {t("noMiners")}
          </Typography>
        </Paper>
      </Grid>
    );

  return (
    <Chart
      options={{
        colors: chartColors,
        labels: minersShare.accountLabels,
        chart: {
          width: 550,
          type: "pie",
        },
        stroke: {
          show: false,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                width: 350,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          position: "right",
          labels: {
            colors: "#ffffff",
            useSeriesColors: false,
          },
        },
      }}
      series={chartSeries}
      type="pie"
      width="550"
    />
  );
};
