import * as properties from "./enviroment";

const isDevelopmentMode = process.env.NODE_ENV === "development";

const isTestNet = process.env.NODE_ENV === "development";

const dAppNetwork = isTestNet ? "Signum-TESTNET" : "Signum";

const {
  poolName,
  dAppName,
  colors,
  poolNodeUrl,
  miningUrl,
  discordUrl,
  explorerUrl,
  faucetUrl,
  walletUrl,
  signumPriceUrl,
  googleTrackingID,
  additionalLinks,
} = properties;

export {
  isDevelopmentMode,
  isTestNet,
  poolName,
  dAppName,
  dAppNetwork,
  colors,
  poolNodeUrl,
  miningUrl,
  discordUrl,
  explorerUrl,
  faucetUrl,
  walletUrl,
  signumPriceUrl,
  googleTrackingID,
  additionalLinks,
};
