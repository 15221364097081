import { WelcomeSection } from "./sections/WelcomeSection";
import { PoolInfo } from "./sections/PoolInfo";
import { BookmarkedMiner } from "./sections/BookmarkedMiner";
import { Miners } from "./sections/Miners";
import { MoreInfo } from "./sections/MoreInfo";

import Grid from "@mui/material/Grid";

export const HomePage = () => (
  <Grid container direction="column">
    <WelcomeSection />
    <PoolInfo />
    <BookmarkedMiner />
    <Miners />
    <MoreInfo />
  </Grid>
);
