/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Box from "@mui/material/Box";

interface Props {
  title: string;
  backgroundColor: string;
  textColor: string;
}

export const SectionTitle = ({ title, backgroundColor, textColor }: Props) => {
  return (
    <Box
      sx={{ background: `url(/assets/nam/overlay.png), ${backgroundColor}` }}
      css={css`
        position: absolute;
        top: -3.25em;
        left: 50%;
        text-align: center;
        text-transform: uppercase;
        display: inline-block;
        font-weight: 700;
        letter-spacing: 0.25em;
        font-size: 0.9em;
        width: 25em;
        height: 3.25em;
        line-height: 3.25em;
        margin-bottom: -3.25em;
        margin-left: -12.5em;
        padding-top: 0.5em;
        border-radius: 8px 8px 0 0;
        color: ${textColor};
        overflow: hidden;

        :before {
          content: "";
          position: absolute;
          bottom: -38px;
          left: -35px;
          width: 35px;
          height: 38px;
          background: url("/assets/nam/shadow.png");
        }

        :after {
          -moz-transform: scaleX(-1);
          -webkit-transform: scaleX(-1);
          -ms-transform: scaleX(-1);
          transform: scaleX(-1);
          content: "";
          position: absolute;
          bottom: -38px;
          right: -35px;
          width: 35px;
          height: 38px;
          background: url("/assets/nam/shadow.png");
        }
      `}
    >
      {title}
    </Box>
  );
};
