import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export const ScrollToTopBtn = () => {
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () =>
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{
          position: "fixed",
          bottom: { xs: "5%", md: "3%" },
          right: { xs: "5%", md: "3%" },
          zIndex: 99,
        }}
      >
        <Fab
          color="secondary"
          size="medium"
          sx={{ color: "white", borderRadius: 1, background: "#d32f2f" }}
        >
          <KeyboardArrowUpIcon fontSize="large" />
        </Fab>
      </Box>
    </Zoom>
  );
};
