import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAccount } from "../../../../../app/hooks/useAccount";
import { useAppContext } from "../../../../../app/hooks/useAppContext";
import { useAppSelector, useAppDispatch } from "../../../../../states/hooks";
import {
  actions,
  selectIsWalletConnected,
} from "../../../../../states/appState";
import { requestWalletConnection } from "../../../../../app/utils/requestWalletConnection";

import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

export const SearchField = () => {
  const { t } = useTranslation();
  const { accountId } = useAccount();
  const { isUnsafeWebsite } = useAppContext();
  const { setSearchMiner } = actions;
  const isWalletConnected = useAppSelector(selectIsWalletConnected);
  const dispatch = useAppDispatch();
  const searchBoxRef = useRef(null);

  const onSubmit = (e: any) => {
    e.preventDefault();
    // @ts-ignore
    const value = searchBoxRef.current.value.trim();
    if (!value) return;

    dispatch(setSearchMiner(value));
  };

  const handleClick = () => {
    if (!isWalletConnected || !accountId) {
      requestWalletConnection();
      return;
    }

    dispatch(setSearchMiner(accountId));
  };

  return (
    <Paper
      component="form"
      onSubmit={onSubmit}
      elevation={5}
      sx={{
        width: "100%",
        px: { xs: 1, lg: 2 },
        py: 1,
        display: "flex",
        alignItems: "center",
        border: 1,
        borderColor: "divider",
      }}
    >
      <InputBase
        inputRef={searchBoxRef}
        sx={{
          fontSize: { xs: 14, lg: 18 },
          ml: 1,
          flex: 1,
        }}
        placeholder={t("yourSignumAddressOrAccountName")}
      />

      {!isUnsafeWebsite && (
        <Tooltip
          title={`${t(
            isWalletConnected ? "viewMinerDetails" : "connectWallet"
          )}`}
          arrow
        >
          <Button
            variant="contained"
            color="error"
            sx={{
              p: 1,
              height: "100%",
              borderRadius: 1,
              color: "white",
              mr: 1,
              display: { xs: "none", lg: "flex" },
            }}
            onClick={handleClick}
          >
            <AccountBalanceWalletIcon fontSize="large" />
          </Button>
        </Tooltip>
      )}

      <Button
        type="submit"
        variant="contained"
        sx={{
          p: 1,
          height: "100%",
          borderRadius: 1,
          color: "white",
        }}
        aria-label="search"
      >
        <SearchIcon fontSize="large" />
      </Button>
    </Paper>
  );
};
