import { useTranslation } from "react-i18next";
import { formatCapacity } from "../../../../app/utils/functions/formatCapacity";
import { formatAmount } from "../../../../app/utils/functions/formatAmount";
import { formatTime } from "../../../../app/utils/functions/formatTime";
import { useAppSelector } from "../../../../states/hooks";
import { selectMiners } from "../../../../states/minersState";
import { selectCurrentRound } from "../../../../states/currentRoundState";
import { selectSignumState } from "../../../../states/signumState";
import { SectionTitle } from "../components/SectionTitle";
import { SearchField } from "./components/SearchField";
import { MinePoolShareChart } from "./components/MinePoolShareChart";
import { TradingChart } from "./components/TradingChart";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import CircularProgress from "@mui/material/CircularProgress";

export const PoolInfo = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const miningData = useAppSelector(selectMiners);
  const {
    isLoading: isLoadingMiningData,
    miners,
    totalPhysicalCapacity,
  } = miningData;

  const currentRound = useAppSelector(selectCurrentRound);
  const {
    isLoading: isLoadingCurrentRound,
    networkInfo,
    bestDeadline,
  } = currentRound;

  const signumState = useAppSelector(selectSignumState);
  const { isLoading: isLoadingSignumState, price } = signumState;

  return (
    <Grid
      container
      sx={{
        py: 4,
        position: "relative",
        background: theme.palette.primary.main,
      }}
    >
      <SectionTitle
        title="NAM SIGNA POOL"
        backgroundColor={theme.palette.primary.main}
        textColor="white"
      />

      <Grid
        container
        mx="auto"
        maxWidth={1650}
        mt={1}
        p={2}
        pb={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container item xs={12} lg={9} spacing={3} mb={2} px={2}>
          <PoolStat
            title={t("blockHeight")}
            value={formatAmount(networkInfo.blockHeight)}
            loading={isLoadingCurrentRound}
          />

          <PoolStat
            title={t("priceUSD")}
            value={"$" + formatAmount(price)}
            loading={isLoadingSignumState}
          />

          <PoolStat
            title={t("miner", { count: miners.length })}
            value={`${miners.length}`}
            loading={isLoadingMiningData}
          />

          <PoolStat
            title={t("poolPhysical")}
            // @ts-ignore
            value={formatCapacity(totalPhysicalCapacity)}
            loading={isLoadingMiningData}
          />

          <PoolStat
            title={t("bestMiner")}
            value={
              bestDeadline.miner
                ? formatTime(bestDeadline.deadline) + " | " + bestDeadline.miner
                : t("waiting") + "..."
            }
            loading={isLoadingCurrentRound}
            fullLength
          />

          <PoolStat
            title={t("networkSize")}
            value={networkInfo.difficulty}
            loading={isLoadingCurrentRound}
            fullLength
          />

          <Grid container item maxWidth={575} mx="auto">
            <SearchField />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        maxWidth={900}
        mt={2}
        mb={6}
        mx="auto"
        justifyContent="space-around"
        alignItems="center"
        wrap="wrap"
        overflow="hidden"
        p={2}
      >
        <Grid item xs={12} md={6}>
          <TradingChart />
        </Grid>

        <Grid item xs={12} md={6}>
          <MinePoolShareChart />
        </Grid>
      </Grid>
    </Grid>
  );
};

interface PoolStatProps {
  title: string;
  value: string;
  fullLength?: boolean;
  loading?: boolean;
}

export const PoolStat = ({
  title,
  value,
  fullLength = false,
  loading = false,
}: PoolStatProps) => {
  return (
    <Grid
      item
      sx={{
        width: fullLength
          ? { xs: "100%", md: "50%" }
          : { xs: "100%", md: "25%" },
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
        <Grid item container>
          <Typography
            variant="h6"
            color="textSecondary"
            align="center"
            width="100%"
          >
            {title}
          </Typography>
        </Grid>

        {loading && (
          <Grid item sx={{ justifyContent: "center", display: "flex" }}>
            <CircularProgress size={39} />
          </Grid>
        )}

        {!loading && (
          <Grid item>
            <Typography
              variant="h6"
              gutterBottom
              width="100%"
              align="center"
              fontWeight={800}
            >
              {value}
            </Typography>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};
