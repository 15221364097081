// ########################################
// # DO NOT PUT SENSIBLE INFORMATION HERE #
// # CLIENT SIDE VARIABLES                #
// ########################################

// The name of the pool
export const poolName = "nam SIGNA pool";

// The name of the dApp
export const dAppName = poolName;

// The URL of the pool in order to consume its API endpoints
// Axios will have an instance based on this URL, so by default this is empty
export const poolNodeUrl = "https://signapool.notallmine.net";

// The URL miners should use in order to send deadlines
export const miningUrl = "https://signapool.notallmine.net";

// The URL of the discord
export const discordUrl = "https://discord.com/invite/DQDz7QmP9P";

// The explorer link
export const explorerUrl = "https://explorer.notallmine.net";

// The faucet link
export const faucetUrl = "https://faucet.signumpool.com/";

// The online wallet link
export const walletUrl = "https://signawallet.notallmine.net/index.html";

// Signum price API
export const signumPriceUrl =
  "https://api.coingecko.com/api/v3/simple/price?ids=signum&vs_currencies=usd";

// Google Analytics Tracking id
// Example of Tracking ID: G-XXXXXX
// DO NOT USE THE DEPRECATED UNIVERSAL ANALYTICS PROPERTY, EXAMPLE: UA-XXXXX
export const googleTrackingID = "G-6J9W30L1QH";

// Pool palette
export const colors = {
  primary: {
    main: "#E5756E",
    light: "#FF827A",
    dark: "#A65550",
  },
  secondary: {
    main: "#1F1F1F",
    light: "#595959",
    dark: "#0A0A0A",
  },
  graph: "#C1C1C1",
};

// Extra url's
export const additionalLinks = {
  links: [
    {
      label: "Blockchain Explorer",
      url: "https://explorer.notallmine.net",
      newTab: true,
    },
    {
      label: "MORE INFORMATION",
      url: "https://signainfo.notallmine.net",
      newTab: true,
    },
    {
      label: "Signum Currency Calculator",
      url: "https://www.fomplo.com/calculator",
      newTab: true,
    },
    {
      label: "Signum Mining Calculator",
      url: "https://www.fomplo.com/signum-mining-calculator",
      newTab: true,
    },
    {
      label: "Signum Commitment Station",
      url: "https://www.fomplo.com/signum-commitment-station",
      newTab: true,
    },
    {
      label: "Server Status - Uptime",
      url: "https://status.notallmine.net",
      newTab: true,
    },
    {
      label: "Detailed Miner Stats",
      url: "https://minerstats.notallmine.net",
      newTab: true,
    },
    {
      label: "Mining Pool Stats",
      url: "https://miningpoolstats.stream/signa",
      newTab: true,
    },
  ],
};
