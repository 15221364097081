import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

export const WelcomeSection = () => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Paper
        sx={{
          mb: 2,
          width: "100%",
          py: 8,
          borderRadius: 0,
          overflow: "hidden",
          background: "url(/assets/nam/banner_pool.webp)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          color: "white",
        }}
      >
        <Typography
          component="h1"
          variant="h3"
          align="center"
          fontWeight={300}
          width="100%"
          sx={{ whiteSpace: "pre-line" }}
        >
          {t("quickJoinGuide")}
        </Typography>

        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography
              align="center"
              variant="h6"
              fontWeight={400}
              sx={{ whiteSpace: "pre-line" }}
              gutterBottom
            >
              {t("quickJoinGuideDescription")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
