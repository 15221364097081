import { useTranslation } from "react-i18next";
import { SectionTitle } from "../components/SectionTitle";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const MoreInfo = () => {
  const { t: customT } = useTranslation("custom");

  return (
    <Grid
      container
      sx={{
        position: "relative",
        backgroundColor: "#262932",
        pt: 4,
      }}
    >
      <SectionTitle
        title={customT("moreInfo")}
        backgroundColor="#262932"
        textColor="white"
      />

      <Typography
        variant="h5"
        align="center"
        style={{ width: "100%", fontWeight: 500, color: "white" }}
        gutterBottom
      >
        {customT("moreInfoTitle")}
      </Typography>

      <Typography
        align="center"
        color="antiquewhite"
        style={{
          width: "100%",
          fontSize: "1.2rem",
          maxWidth: 900,
          marginLeft: "auto",
          marginRight: "auto",
          paddingBottom: "1em",
        }}
        gutterBottom
      >
        {customT("moreInfoDescription")}
      </Typography>
    </Grid>
  );
};
