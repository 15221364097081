import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../states/hooks";
import { selectBookmarkedMiner } from "../../../../states/appState";

import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

export const WelcomeSection = () => {
  const { t: customT } = useTranslation("custom");
  const bookmarkedMinerID = useAppSelector(selectBookmarkedMiner);

  return (
    <Grid container>
      <Paper
        sx={{
          width: "100%",
          py: 8,
          borderRadius: 0,
          overflow: "hidden",
          color: "white",
          background: "url(/assets/nam/banner_pool.webp)",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Grid container justifyContent="center" mb={1}>
          <img
            src="/assets/nam_main_dark_logo.png"
            alt="nam SIGNA pool logo"
            height={48}
          />
        </Grid>

        <Typography
          component="h1"
          variant="h4"
          align="center"
          width="100%"
          fontWeight={400}
        >
          NOT ALL MINE
        </Typography>

        {!bookmarkedMinerID && (
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Typography
                align="center"
                variant="h6"
                fontWeight={300}
                sx={{ whiteSpace: "pre-line" }}
              >
                {customT("welcomePoolMessageDescription")}
              </Typography>
            </Grid>

            <Alert
              severity="success"
              style={{ margin: "1em 0", fontSize: "1rem" }}
            >
              {customT("miningRewardBonus")}
            </Alert>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};
