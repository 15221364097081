import { useTranslation } from "react-i18next";
import { BookMarkedMiner as BookMarkedMinerTable } from "../../../../app/components/Tables/BookMarkedMiner";
import { SectionTitle } from "../components/SectionTitle";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

export const BookmarkedMiner = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        position: "relative",
      }}
    >
      <SectionTitle
        title={t("miner_other")}
        backgroundColor={theme.palette.background.default}
        textColor={theme.palette.text.primary}
      />

      <Grid
        container
        mx="auto"
        maxWidth={760}
        mt={1}
        p={2}
        direction="row"
        alignItems="center"
      >
        <Typography variant="h6" gutterBottom>
          {t("bookmarkedMiner")}
        </Typography>

        <BookMarkedMinerTable />
      </Grid>
    </Grid>
  );
};
