import { useEffect } from "react";

export const TradingChart = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.livecoinwatch.com/static/lcw-widget.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div
      className="livecoinwatch-widget-1"
      lcw-coin="SIGNA"
      lcw-base="USD"
      lcw-secondary="BTC"
      lcw-period="d"
      lcw-color-tx="#ffffff"
      lcw-color-pr="#abb8c3"
      lcw-color-bg="#1f2434"
      lcw-border-w="1"
      lcw-digits="8"
    ></div>
  );
};
